import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img404 from '../images/404.gif'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 | Not found" />
    {/* IMAGE */}
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-6 offset-3">
            <img src={Img404} alt="Cow with blonde hair" className="w-100" />
          </div>
          <div className="col-12 text-center">
            <h1>Questa pagina non esiste</h1>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
